/**
 * FORMS EVENT BUS
 *
 * Allows fetching of form/field data and validation from all forms and fields.
 *
 * See /vue/global/classes/eventBus.js for more info on the event bus
 */

import {EventBus, FormResponse} from '@teemill/common/classes';

export const formBus = new EventBus('form');

export const forms = {
  /**
   * ? Event bus methods
   *
   * ? See /vue/global/classes/eventBus.js for more info on the event bus
   */
  on(event, callback) {
    formBus.on(event, callback);
  },

  off(event, callback) {
    formBus.off(event, callback);
  },

  request(event, data, callback) {
    formBus.request(event, data, callback);
  },

  reply(event, callback) {
    formBus.reply(event, callback);
  },

  /**
   * Request the form/field validation over the event bus
   *
   * Returns a promise which resolves any validation errors
   *
   * @param { String } name The name given to the form
   * @param { String } type `field/form`
   */
  validate(name, type = 'form') {
    return new FormResponse((resolve, reject) =>
      formBus.request(`${type}-validate-${name}`).then(resolve).catch(reject)
    );
  },

  /**
   * Get a form fields' value
   *
   * @param { String } name The name of the field
   */
  value(name) {
    return formBus.request(`field-value-${name}`);
  },

  /**
   * Get all of the values of all fields in a form
   *
   * @param { String } name The name of the form
   */
  values(name) {
    return formBus.request(`form-values-${name}`);
  },
};

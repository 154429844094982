import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import castArray from 'lodash/castArray';

export default class FormResponse extends Promise {
  errors = {};

  /**
   * @name all
   * @description Handle all fields with a single callback
   *
   * @param {callback} handler
   *
   * @return {FormResponse}
   */
  all(handler) {
    return this.catch(errors => {
      Object.keys(errors).forEach(fieldName => {
        const fieldErrors = errors[fieldName];
        const formatted = {};

        Object.keys(fieldErrors).forEach(error => {
          formatted[error] = handler;
        });

        this.handle(fieldName, formatted);
      });
    });
  }

  /**
   * @name Handle
   * @description Handle a specific validation field failure
   *
   * @param {string} name The field to handle
   * @param {object} handlers The validation rules to handle
   *
   * @return {FormResponse}
   */
  handle(name, handlers) {
    return this.catch(errors => {
      this.errors = errors;

      Object.keys(handlers).forEach(key => {
        try {
          const handler = handlers[key];

          if (typeof handler === 'function' && has(this.errors[name], key)) {
            handler(castArray(this.errors[name][key])[0]);

            delete this.errors[name][key];

            if (isEmpty(this.errors[name])) {
              delete this.errors[name];
            }
          }
        } catch (e) {
          // eslint-disable-next-line
              console.error('formError: ', e);
        }
      });

      return new FormResponse((resolve, reject) => {
        const promise = isEmpty(this.errors)
          ? Promise.resolve(true)
          : Promise.reject(this.errors);

        promise.then(resolve).catch(reject);
      });
    });
  }

  /**
   * @name Oops
   * @description Display the first validation error as a snackbar then
   *              prevent the propagation of errors to any following handler
   *
   * @return {FormResponse}
   */
  oops() {
    return this.catch(errors => {
      const firstField = Object.values(errors)[0];
      const firstError = Object.values(firstField)[0];

      snackbar.error(firstError[0]);

      // Remove all errors to prevent propagation
      this.errors = {};

      // Create a new, resolved form response
      return new FormResponse((resolve, reject) => {
        Promise.resolve(true).then(resolve).catch(reject);
      });
    });
  }

  /**
   * @name Success
   * @description Handle a successful validation check
   *
   * @param {callback} callback
   *
   * @return {FormResponse}
   */
  success(callback) {
    return this.then(callback);
  }
}
